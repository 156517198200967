<template>
  <v-dialog
    v-model="isActiveModal"
    :max-width="widthModal || variables.widthModal"
    :persistent="isPersistent">

    <template v-slot:activator="{}">
      <slot name="btnActivator" />
    </template>
    
    <div class="modal-cadastro2">
      <slot />
    </div>
  </v-dialog>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: 'Modal',

  props: {
    activeModal: {
      type: Boolean,
      default: false,
      required: true
    },

    widthModal: {
      type: Number,
      default: 0
    },

    isPersistent: {
      type: Boolean,
      default: false,
      require: false
    }
  },

  computed: {
    variables: () => variables,

    isActiveModal: {
      get: function () {
        return this.activeModal
      },
      
      set: function (newValue) {
        newValue || this.$emit('closeModal', newValue)
      }
    }
  },

  methods: {
    onClickOutside () {
      this.isActiveModal = true
      return
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/modal-cadastro2';
</style>