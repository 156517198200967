import { REQUIRED_FIELD } from '@/utils/validationMessagesUtils'

export default {
  codErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.cod.required && errors.push(REQUIRED_FIELD)
    return errors
  },
  passwordErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.password.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.password.minLength && errors.push('Campo deve ter no mínimo 4 caracteres')
    return errors
  }
}
