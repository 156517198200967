<template>
  <v-form class="box-form">
    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6" v-if="item.dtCreated">
          <v-text-field
            outlined
            dense
            v-model="item.dtCreated"
            v-mask="['##/##/####']"
            :label="$gettext('Data Cadastro')"
            disabled>
            <template v-slot:append>
              <v-icon v-on="on">
                event
              </v-icon>
            </template>
          </v-text-field>

        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field
            v-model="item.name"
            outlined
            dense
            label="Nome Completo:"
            disabled/>
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <DateModal 
            :date.sync="item.birth"
            :isDateInit="item.birth ? true : false"
            :propOutlined="true"
            :dateInit="item.birth"
            :label="$gettext('Data de Nascimento')"
            disabled
          />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput " sm="6">
          <v-text-field 
            v-model="item.email"
            label="Email:"
            outlined
            dense 
            disabled />
        </v-col>
        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel" 
            v-model="item.document" 
            outlined
            dense
            label="CPF/CNPJ:" 
            v-mask="['##.###.###/####-##', '###.###.###-##']"
            disabled />
        </v-col>

        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel" 
            outlined
            dense
            v-model="item.rg" 
            label="RG:"
            disabled />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel" 
            v-model="item.phone"
            label="Telefone Comercial:"
            outlined
            dense
            v-mask="['(##) ####-####', '(##) #####-####']"
            class="input-phone"
            disabled
          >
            <template v-slot:prepend>
              <v-btn
                depressed 
                tile
                disabled
                class="ma-0 btn-phone"
                :maxWidth="'40'"
                :minWidth="'40'"
              >
                +55
              </v-btn>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaGeneros"
            v-model="item.gender"
            dense
            outlined
            label="Gênero:"
            disabled
          />
        </v-col>

        <v-col cols="6" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaEstadosCivies"
            v-model="item.maritalStatus"
            dense
            outlined
            label="Estado Civil:"
            disabled
          />
        </v-col>

        <template v-if="item.maritalStatus === 'married'">
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field
              v-model="item.nameSpouse"
              outlined
              dense
              label="Nome do Cônjuge:"
              disabled />
          </v-col>

          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <DateModal 
              :date.sync="item.birthSpouse"
              :isDateInit="item.birthSpouse ? true : false"
              :propOutlined="true"
              :dateInit="item.birthSpouse"
              :label="$gettext('Data de Nascimento')"
              disabled
            />
          </v-col>
        </template>



        <v-col cols="12" class="modal-cadastro-form-col-imput">
          <template v-for="(it, key, index) in item.children">
            <v-row :key="index">
              <v-col cols="12" class="pt-0">
                <v-card-title>
                  Filho (a) <span v-if="exibirQtFilhos(item.children)" class="ml-1"> - {{ index + 1 }}</span>
                  <v-btn
                    fab dark 
                    color="red" 
                    class="fab-remove-item ml-1"
                    @click="removeItem(key)"
                  >
                    <v-icon dark size="10">mdi-minus</v-icon>
                  </v-btn>
                </v-card-title>
              </v-col>

              <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                <v-text-field
                  v-model="it.name"
                  outlined
                  dense
                  label="Nome do filho(a):"
                  disabled />
              </v-col>

              <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                <DateModal 
                  :date.sync="it.birth"
                  :isDateInit="it.birth ? true : false"
                  :propOutlined="true"
                  :dateInit="it.birth"
                  :label="$gettext('Data de Nascimento')"
                  disabled
                />
              </v-col>
            </v-row>
          </template>
        </v-col>
        
      </v-row>
    </v-card-text>

    <v-card-actions class="modal-cadastro-footer">
      <v-btn
        @click="proximaEtapa(2)"
        :color="variables.colorPrimary"
        class="br-btn">Avançar</v-btn>
      <v-btn 
        :color="variables.colorPrimary"
        @click="reprovarRevenda"
        class="br-btn br-btn-cancelar">Reprovar revenda</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import { mask } from 'vue-the-mask'
// eslint-disable-next-line
import variables from '@/assets/styles/helpers/_variables.scss'
import { size } from 'lodash'

import DateModal from '@/views/components/dateModal'

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'



export default {
  name: 'ModalCadastroEntPendentesDadosPessoais',
  mixins: [modalCadastro],
  directives: {mask},
  components: {
    DateModal
  },
  data: () => ({
    menuDate: false,
    menuDateSpouse: false,
    dtBirthString: '',
    dtCadastroString: '',
    dtSpouseString: '',
    activeModal: false,
    loading: false,
    listaEstadosCivies: [
      { text: 'Solteiro (a)', value: 'single' },
      { text: 'Casado (a)', value: 'married' },
      { text: 'Divorciado (a)', value: 'divorced' },
      { text: 'Viúvo (a)', value: 'widowed' },
    ],
    listaGeneros: [
      { text: 'Masculino', value: 'M' },
      { text: 'Feminino', value: 'F' }
    ],
    qtdItemForm: 0,
  }),

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('pendingEntities', ['item']),
    ...mapGetters('estabelecimento', {
      listaItensEstabelecimento: 'listaItensSelect',
      listaItensCredenciador: 'listaItensSelectCredenciador',
      qtdItemFormChildren: 'qtdItemFormChildren',
    }),

    variables: () => variables,
  },

  methods: {
    ...mapActions('estabelecimento', ['setQtdItemFormChildren']),
    ...mapActions('estabelecimentoComissao', {
      obterItensSelectParentId: 'obterItensSelect',
      clearDadosComissaoCredenciador: 'clearDados'
    }),


    addItem () {
      this.setQtdItemFormChildren()
      this.$set(this.item.children, `item${this.qtdItemFormChildren}`,
        {
          name: '',
          birth: ''
        }
      )
    },

    removeItem(item) {
      this.$delete(this.item.children, item)
    },

    exibirQtFilhos(children) {
      return size(children) > 1
    },

    proximaEtapa() {
      this.$emit('proximoCadastro')
    },

    reprovarRevenda() {
      this.$emit('reprovarRevenda')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '../../estabelecimento/components/style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-top: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }

  .fab-remove-item {
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
  }

  .input-phone::v-deep {
    .v-input__prepend-outer {
      margin-top: 0 !important;
      margin-right: 0 !important;
    }

    .v-btn {
      height: 40px !important;
      border-radius: 4px 0 0 4px;
      background: #d3d3d3 !important;
      color: #000 !important;
    }
  }
</style>