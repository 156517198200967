<template>
  <v-form class="box-form">
    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-autocomplete
            v-model="item.parentId"
            :items="listaItensCredenciador"
            :loading="isLoadingAutoComplete"
            :search-input.sync="searchText"
            :menu-props="{'content-class': 'autocomplete-menu'}"
            @keyup="searchAutoComplete"
            item-text="text"
            item-value="value"
            :label="`Selecione um ${getEntityType(3)}`"
            class="autocomplete"
            outlined
            dense
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Buscando...
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>  {{ item.text }} </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            v-model="item.cod"
            :error-messages="codErrors"
            outlined
            label="Código Revenda:"
            type="number"
            dense
            @input="$v.item.cod.$touch()"
            @blur="$v.item.cod.$touch()" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field
            label="serial"
            v-model="item.serial"
            type="text"
            outlined
            dense
            disabled
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            label="Senha" 
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            v-model="item.password"
            height="30px"
            dense
            :error-messages="passwordErrors"
            @input="$v.item.password.$touch()" 
            @blur="$v.item.password.$touch()" 
            />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="modal-cadastro-footer">
      <v-btn
        v-if="permiteAcao({path: '/gestao/entidades/pendentes/aprovar'}, 'add')"
        @click="aprovarRevenda"
        :color="variables.colorPrimary"
        class="br-btn">Aprovar revenda</v-btn>
      <v-btn 
        v-if="permiteAcao($route, 'delete')"
        :color="variables.colorPrimary"
        @click="reprovarRevenda"
        class="br-btn br-btn-cancelar">Reprovar revenda</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import { isOnlyNumber } from '@/utils/validationUtils'
import variables from '@/assets/styles/helpers/_variables.scss'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'
import validacao from './validacaoFormCadastroPessoais'

export default {
  name: 'FormCadastroAprovação',
  mixins: [validationMixin, modalCadastro],
  data: () => {
    return {
      showPassword: false
    }
  },

  watch: {
    'touch' (val) {
      if (val)  this.$v.item.$touch()
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('pendingEntities', ['item']),
    ...mapGetters('estabelecimento', {
      listaItensCredenciador: 'listaItensSelectCredenciador',
    }),
    ...validacao,

    variables: () => variables,
  },

  methods: {
    ...mapActions('', ['']),

    reprovarRevenda() {
      this.$emit('reprovarRevenda')
    },

    aprovarRevenda() {
      this.$emit('aprovarRevenda')
    },
  },

  validations () {
    return {
      item: {
        cod: { required, isOnlyNumber },
        parentId: { required },
        password: { required, minLength: minLength(4) },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../estabelecimento/components/style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-top: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }

  .fab-remove-item {
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
  }
</style>
