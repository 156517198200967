<template>
  <Modal
    :activeModal="activeModal"
    @closeModal="closeModal">
    <v-card v-if="loadingModal">
      <v-col class="d-flex justify-center align-center" style="min-height: 150px; width: 100%;">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-card>
    <v-card v-else>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        {{`Validando Agência Pendente: ${item.name}`}}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <div class="abas" width="100%">
        <v-tabs
          v-model="tab"
          height="30px"
        >
          <v-tab href="#tab-1" class="abas-item" v-if="permiteAcao({path: '/gestao/entidades/pendentes/aprovar'}, 'add')">
            Proprietário
          </v-tab>

          <v-tab href="#tab-2" class="abas-item" v-if="permiteAcao({path: '/gestao/entidades/pendentes/aprovar'}, 'add')">
            Residência
          </v-tab>

          <v-tab href="#tab-3" class="abas-item" v-if="permiteAcao({path: '/gestao/entidades/pendentes/aprovar'}, 'add')">
            Dados Bancários
          </v-tab>

          <v-tab href="#tab-4" class="abas-item" v-if="permiteAcao({path: '/gestao/entidades/pendentes/aprovar'}, 'add')">
            Aprovação
          </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">
          <FormCadastroPessoais @reprovarRevenda="reprovarRevenda" @proximoCadastro="proximoCadastroEndereco" />
        </v-tab-item>

        <v-tab-item
          :key="2"
          :value="'tab-2'">
          <FormCadastroEnderecos @reprovarRevenda="reprovarRevenda" @proximoCadastro="proximoCadastroContasBancarias" />
        </v-tab-item>
        
        <v-tab-item
          :key="3"
          :value="'tab-3'">
          <FormCadastroContasBancarias @reprovarRevenda="reprovarRevenda" @proximoCadastro="proximoCadastroPromoterECod" />
        </v-tab-item>

        <v-tab-item
          :key="4"
          :value="'tab-4'" >
          <FormCadastroAprovacao :touch="touchAprovacao" @reprovarRevenda="reprovarRevenda" @aprovarRevenda="submit" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
// eslint-disable-next-line
import { formatPhone } from '@/core/service/utils'
import variables from '@/assets/styles/helpers/_variables.scss'
import axios from 'axios'
import config from '@/core/config'
// eslint-disable-next-line
import { merge, size, map, each, find } from 'lodash'
// eslint-disable-next-line
import { isOnlyNumber, isValidCpfOrCnpj, isValidPhone, validateDDD } from '@/utils/validationUtils'
import moment from 'moment'
import { errorSnackbar } from '@/core/service/utils'

import validacao from './validacao'


// Componentes
import FormCadastroPessoais from './formCadastroPessoais'
import FormCadastroEnderecos from './formCadastroEnderecos'
import FormCadastroContasBancarias from './formCadastroContasBancarias'
import FormCadastroAprovacao from './formCadastroAprovacao'
import Modal from '@/views/components/modal'


/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

export default {
  name: 'ModalCadastroEntidadesPendentes',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  components: {
    Modal,
    FormCadastroPessoais,
    FormCadastroEnderecos,
    FormCadastroContasBancarias,
    FormCadastroAprovacao,
  },
  data: () => ({
    menuDate: false,
    dtBirthString: '',
    activeModal: false,
    loading: false,
    tab: 'tab-1',
    cepValidate: false,
    cepValidateResponsible: false,
    loadingModal: false,
    loadingProdutos: false,
    listaTipoEstabelecimento: [
      { text: '1 - Grande', value: 1 },
      { text: '2 - Médio', value: 2 },
      { text: '3 - Pequeno', value: 3 }
    ],
    listaEstadosCivies: [
      { text: 'Solteiro (a)', value: 'Solteiro (a)' },
      { text: 'Casado (a)', value: 'Casado (a)' },
      { text: 'Divorciado (a)', value: 'Divorciado (a)' },
      { text: 'Viúvo (a)', value: 'Viúvo (a)' },
    ],
    dados: {
      pessoais: {}
    },

    loadingBtn: false,
    touchAprovacao: false,
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('pendingEntities', {
      item: 'item',
    }),
    ...validacao,

    variables: () => variables,
  },

  mounted () {
    Events.$on('cadastro::openModalCadastro2', () => {
      this.openModal({})
    })

    this.getBankList()
  },

  watch: {
    'item.birth' (val) {
      if (val) {
        this.dtBirthString = moment(val).format('DD/MM/YYYY')
      }
    }
  },
  methods: {
    ...mapActions('pendingEntities', ['getItens', 'getItem', 'limparItem','deleteItem','aprovarItem']),
    ...mapActions('estabelecimento', ['obterItensSelect', 'getBankList']),

    mergeItens (estabelecimento, credenciador) {
      return merge(estabelecimento, credenciador)
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      Events.$emit('cadastro::closeModal')
      this.tab = 'tab-1'
      this.$v.item.$reset()
      this.dados.validacaoPessoais ? this.dados.validacaoPessoais.$reset() : ''
      this.dados.validacaoEnderecos ? this.dados.validacaoEnderecos.$reset() : ''
      this.dados.validacaoAccounts ? this.dados.validacaoAccounts.$reset() : ''
      Events.$emit('closeModal::modalCadastro')
      this.cepValidate = false,
      this.cepValidateResponsible = false
      this.dtBirthString = ''
      
    },

    async buscarCep () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.cep)){
        const result = await axios.get(config.apiViaCep + this.item.cep + "/json/")
        if (result.data.erro) {
          this.cepValidate = false
          return
        }

        this.cepValidate = true
        this.item.address = result.data.logradouro
        this.item.state = result.data.uf
        this.item.city = result.data.localidade
        this.item.neighborhood = result.data.bairro
      }
    },

    async buscarCepResponsible () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.responsible.cep)){
        const result = await axios.get(config.apiViaCep + this.item.responsible.cep + "/json/")
        if (result.data.erro) {
          this.cepValidateResponsible = false
          return
        }

        this.cepValidateResponsible = true
        this.item.responsible.address = result.data.logradouro
        this.item.responsible.state = result.data.uf
        this.item.responsible.city = result.data.localidade
        this.item.responsible.neighborhood = result.data.bairro
      }
    },

    closeModalDatePicker (date) {
      this.dtBirthString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.menuDate = false
    },

    reprovarRevenda () {
      this.loadingBtn = true
      this.loadingModal = true
      let msg = {
        toggle: true,
        type: 'error',
        msg: '',
      }

      this.deleteItem(this.item).then(() => {
        this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })

        msg.type = 'success'
        msg.msg = 'Revenda recusada com sucesso!'
      }).catch(err => {
        msg.msg = err.error
        
      }).finally(()=> {
        this.loadingBtn = false
        this.loadingModal = false
        Events.$emit('snackbarCadastro::msg', msg)
      })
    },

    submit () {
      if (this.$v.item.cod.$invalid) {
        const msg = {
          toggle: true,
          type: 'error',
          msg: 'Código da agência não informado.'
        }
        Events.$emit('snackbarCadastro::msg', msg)
        return
      }

      if (this.$v.item.parentId.$invalid) {
        const msg = {
          toggle: true,
          type: 'error',
          msg: 'Promotor da agência não informado.'
        }
        Events.$emit('snackbarCadastro::msg', msg)
        return
      }
      
      if (this.$v.item.password.$invalid) {
        const msg = {
          toggle: true,
          type: 'error',
          msg: 'Senha da instalação não informado.'
        }
        Events.$emit('snackbarCadastro::msg', msg)
        return
      }

      if (!this.item.id) {
        const msg = {
          toggle: true,
          type: 'error',
          msg: 'Nenhuma revenda pendente selecionada. Por favor recarrege a página.'
        }
        Events.$emit('snackbarCadastro::msg', msg)
        return
      }

      const dados = {
        parentId: this.item.parentId,
        cod: this.item.cod,
        id: this.item.id,
        password: this.item.password,
      }

      this.loadingBtn = true
      this.loadingModal = true

      let msg = {
        toggle: true,
        type: 'error',
        msg: '',
      }

      this.aprovarItem(dados).then(() => {
        this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })

        msg.type = 'success'
        msg.msg = 'Revenda aprovada com sucesso!'
      }).catch(err => {
        msg.msg = err.error 
      }).finally(() => {
        this.loadingBtn = false
        this.loadingModal = false
        Events.$emit('snackbarCadastro::msg', msg)
        this.closeModal()
      })
    },

    proximoCadastroEndereco() {
      this.tab = 'tab-2'
    },
    
    proximoCadastroContasBancarias() {
      this.tab = 'tab-3'
    },

    proximoCadastroPromoterECod() {
      this.tab = 'tab-4'
    },

    async openModal (item) {
      this.activeModal = true

      this.loadingModal = true

      await Promise.all([
        this.obterItensSelect({ pageSize: this.$store.getters.ItensPaginacao }),
      ])
      .then(() => {
        if (item.id) {
          this.dadosItem = item
        }
      })
      .catch((err) => {
        errorSnackbar(err.error)
      })
      .finally(() =>  this.loadingModal = false)
    },
  },

  validations () {
    return  {
      item: {
        cod: { required, isOnlyNumber },
        parentId: { required },
        password: { required, minLength: minLength(4) },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../estabelecimento/components/style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-bottom: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }
</style>