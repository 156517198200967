<template>
  <v-form class="box-form">
     <v-card-text class="modal-cadastro-form">
      <v-row v-for="(it, key, index) in item.adresses" :key="index">

        <v-col cols="12" class="modal-cadastro-form-col-imput" v-if="exibirTitle(item.adresses)">
        <v-card-title>
          Endereço {{ index + 1 }}

          <v-btn
            fab dark 
            color="red" 
            class="fab-remove-item ml-1"
            @click="removeItem(key)"
          >
            <v-icon dark size="10">mdi-minus</v-icon>
          </v-btn>
        </v-card-title>
        </v-col>
        
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaTipoEndereco"
            v-model="it.typeAddress"
            dense
            outlined
            label="Tipo de endereço:"
            disabled
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-select
            :items="listaSituacoesEndereco"
            v-model="it.typeSituation"
            dense
            outlined
            label="Situação:"
            disabled
          />
        </v-col>


        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            type="tel"
            v-model="it.cep"
            outlined
            dense
            @keyup="buscarCep(it)"
            label="CEP:"
            v-mask="['#####-###']"
            class="imput-cep"
            disabled
            />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field
            v-model="it.address"
            label="Endereço:"
            outlined
            dense
            disabled />
          </v-col>
          
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field 
            v-model="it.neighborhood"
            label="Bairro:"
            outlined
            dense
            disabled />
        </v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field type="tel"
            v-model="it.number"
            label="Número:"
            outlined
            dense
            disabled
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12">
          <v-text-field
            v-model="it.complement"
            label="Complemento:"
            outlined
            dense
            disabled
          />
        </v-col>

        <!-- componente de select de Estado e cidade -->
        <searchUfCity
          @addUf="it.state = arguments[0]"
          @addCity="it.city = arguments[0]"
          :propDense="true"
          :propOutlined="true"
          :propLabelUF="'UF'"
          :propUf="it.state"
          :propCity="it.city"
          propDisabled
          />

        <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field v-model="item.lat" label="Latitude" type="number"/></v-col>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field v-model="item.lng" label="Longitude" type="number"/></v-col> -->
        <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.complement" label="Complemento:" /></v-col> -->
      </v-row>
    </v-card-text>

    <v-card-actions class="modal-cadastro-footer">
      <v-btn
        @click="proximaEtapa"
        :color="variables.colorPrimary"
        class="br-btn">Avançar</v-btn>
      <v-btn 
        :color="variables.colorPrimary"
        @click="reprovarRevenda"
        class="br-btn br-btn-cancelar">Reprovar revenda</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
// import Events from '@/core/service/events'
// eslint-disable-next-line
import { isOnlyNumber, isValidCpfOrCnpj } from '@/utils/validationUtils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { size, compact, map, flatMap } from 'lodash'
// import moment from 'moment'
// import { errorSnackbar } from '@/core/service/utils'
import axios from 'axios'
import config from '@/core/config'

import searchUfCity from '@/views/components/searchUfCity'

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

export default {
  name: 'ModalCadastroEntPendentesDadosEnderecos',
  mixins: [modalCadastro],
  directives: {mask},
  components: {
    searchUfCity
  },
  data: () => ({
    menuDate: false,
    loadingCep: false,
    menuDateSpouse: false,
    dtBirthString: '',
    dtCadastroString: '',
    dtSpouseString: '',
    activeModal: false,
    loading: false,
    listaTipoEndereco: [
      { text: 'Casa', value: 'house' },
      { text: 'Apartamento', value: 'apartment' },
      { text: 'Comercial', value: 'commercial' }
    ],
    listaGeneros: [
      { text: 'Masculino', value: 'M' },
      { text: 'Feminino', value: 'F' }
    ],

    listaSituacoesEndereco: [
      { text: 'Próprio', value: 'own' },
      { text: 'Alugado', value: 'rented' },
      { text: 'Outro', value: 'other' },
    ],

    qtdItemForm: 1,

    form: {
      item1: {
        typeAddress: 'commercial',
        typeSituation: '',
        cep: '',
        address: '',
        neighborhood: '',
        number: '',
        state: '',
        city: '',
        cepValidate: false,
        complement: ''
      }
    }
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('pendingEntities', ['item']),

    variables: () => variables,
  },

  mounted () {
    this.qtdItemForm = size(this.item.adresses)
  },
  methods: {
    ...mapActions('pendingEntities', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect', 'obterItensSelectProducts', 'obterEntityProduct', 'obterItensSelect', 'getItem']),

    openModal () {
      this.activeModal = true
    },

    async buscarCep (it) {
      const vm = this
      if(/^[0-9]{5}-[0-9]{3}$/.test(it.cep)){
        vm.loadingCep = true
        const result = await axios.get(config.apiViaCep + it.cep + "/json/")
        if (result.data.erro) {
          vm.loadingCep = false
          it.cepValidate = false
          return
        }

        it.cepValidate = true
        it.address = result.data.logradouro
        it.state = result.data.uf
        it.city = result.data.localidade
        it.neighborhood = result.data.bairro

        vm.loadingCep = false
      }
    },

    addItem () {
      this.qtdItemForm++
      this.$set(this.item.adresses, `item${this.qtdItemForm}`,
        {
          typeAddress: 'commercial',
          typeSituation: '',
          cep: '',
          address: '',
          neighborhood: '',
          number: '',
          state: '',
          city: '',
          cepValidate: false
        }
      )
    },

    exibirTitle (form) {
      return size(form) > 1 ? true : false
    },

    removeItem(item) {
      this.$delete(this.item.adresses, item)
    },

    proximaEtapa () {
      this.$emit('proximoCadastro')
    },

    reprovarRevenda() {
      this.$emit('reprovarRevenda')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '../../estabelecimento/components/style.scss';
  .modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
    .v-input__slot {
      margin-bottom: 20px !important;
    }
    .v-text-field__details {
      bottom: 5px;
    }
  }

  .modal-cadastro2 {
    .br-btn {
      width: 100%;
      max-width: 250px;
      margin: 0 !important;

      &-cancelar {
        margin-top: 10px !important;
      }
    }

    > .v-card .modal-cadastro-footer {
      flex-direction: column;
    }
  }

  .fab-remove-item {
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
  }

  .imput-cep::v-deep {
    .v-input__append-outer {
      margin-top: 0 !important;
      margin-left: 0 !important;
    }

    .v-btn {
      height: 40px !important;
      border-radius: 0px 4px 4px 0px;
      // background: #d3d3d3 !important;
      // color: #000 !important;
    }
  }
</style>